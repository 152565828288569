import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Input, Button} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {StoreSummarySection, StoreStatusSection} from './comp.js';
import {StaticImage} from 'gatsby-plugin-image';
import NoStore from '../../images/page-not-found-688965_1280.png';

function StoreDetail() {
  const [store, setStore] = React.useState(null);
  const [isReady, setReady] = React.useState(false);
  const [actions] = useOutlet('actions');

  async function updateStore() {
    let storeId = getParameterByName('sid');

    if (storeId) {
      try {
        actions.setLoading(true);
        let store = await actions.fetchStoreById(storeId);
        setStore({
          // ---- test
          image:
            'https://www.revtel.tech/static/c9ae836574b8fae8f80a5dd4b266200e/9f108/revtel-office.png',
          address: '台北市',
          phone: '0900000000',
          last: [
            {num: 10, time: '1622592070000'},
            {num: 11, time: '1622592006000'},
            {num: 12, time: '1622592000050'},
            {num: 13, time: '1622592000004'},
            {num: 14, time: '1622592000003'},
          ],
          // ---- test
          ...store,
        });
        actions.setLoading(false);
      } catch (e) {
        actions.setLoading(false);
      }
      setReady(true);
    } else {
      setReady(true);
    }
  }

  useEffect(updateStore, []);

  let isStoreOK = store && store.isActivated;

  return (
    <ContentWrapper css={!isStoreOK ? 'justify-content: center' : ''}>
      {isReady && isStoreOK && <StoreSummarySection store={store} />}
      {/*isReady && isStoreOK && <hr style={{width: '50%'}} />*/}
      {isReady && isStoreOK && (
        <StoreStatusSection store={store} update={updateStore} />
      )}

      {isReady && !isStoreOK && (
        <img
          src={NoStore}
          alt="Store Not Found or Not Ready"
          style={{width: '80%', maxWidth: 400}}
        />
      )}
    </ContentWrapper>
  );
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #eeeeee;
  min-height: calc(100vh - 64px - 50px);
  width: 100%;
  ${(props) => props.css || ''};
`;

export default StoreDetail;
