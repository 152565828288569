import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Input, Button} from 'antd';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import ThemeContext from '../../Theme';
import {Refresh, LocationOn, Call} from '@styled-icons/material';

function StoreSummarySection({css = '', store}) {
  const [fallBackImg, setFallBackImg] = React.useState();

  function cleanPhoneFormat(_p = '') {
    let phone = _p.replace(/\s/g, '').replace(/-/g, '').replace(/^0/, '+886');
    return phone === '' ? null : phone;
  }

  const setFallBackImgOnError = () => {
    if (!fallBackImg) {
      setFallBackImg(
        'https://www.revtel.tech/static/c9ae836574b8fae8f80a5dd4b266200e/9f108/revtel-office.png',
      );
    }
  };

  return (
    <Col
      css={`
        ${css || ''};
        padding: 50px;
        padding-bottom: 10px;
      `}>
      <img
        src={fallBackImg || store.image}
        onError={setFallBackImgOnError}
        alt="Logo"
        style={{width: '50%', minWidth: 300, borderRadius: 20}}
      />
      <Col css="width:100%;margin-top:20px;">
        <h1 style={{marginTop: 10, color: '#1d2d44'}}>{store.name}</h1>
        <a
          style={{marginTop: 10, color: '#3e5c76'}}
          href={'https://www.google.com.tw/maps/place/' + store.address}
          target="_blank"
          rel="noreferrer">
          <LocationOn style={{width: 15, height: 15}} /> {store.address}
        </a>
        {cleanPhoneFormat(store.phone) && (
          <a
            href={`tel:${cleanPhoneFormat(store.phone)}`}
            style={{marginTop: 10, color: '#748cab'}}>
            <Call style={{width: 15, height: 15}} /> {store.phone}
          </a>
        )}
      </Col>
    </Col>
  );
}

function StoreStatusSection({css = '', store, update}) {
  const theme = React.useContext(ThemeContext);

  function avgWaitingTime(waitQueue = []) {
    if (waitQueue.length <= 1) {
      return Number.MAX_VALUE;
    }

    let avgTime =
      waitQueue.reduce((acc, _, idx, arr) => {
        if (idx === arr.length - 1) {
          return acc;
        } else {
          return acc + Math.ceil((arr[idx] - arr[idx + 1]) / 60000);
        }
      }, 0) /
      (waitQueue.length - 1);

    return parseInt(avgTime) === 0 ? 1 : parseInt(avgTime);
  }

  return (
    <Col
      css={`
        padding-top: 10px;
        margin-bottom: 50px;
        background-color: #fffcf2;
        border-radius: 20px;
        width: 50%;
        min-width: 300px;
        ${css || ''};
      `}>
      <Button style={{marginBottom: 10}} onClick={update}>
        重新讀取現在號碼
      </Button>
      <NumPad
        bgColor={theme.primary}
        textColor={theme.secondary}
        onClick={update}>
        {(store.current + '').padStart(3, 0)}
        <Refresh
          style={{
            width: 25,
            height: 25,
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          color="orange"
        />
      </NumPad>

      <div style={{margin: 20, textAlign: 'center'}}>
        <h2 style={{color: '#1D69A1'}}>請注意避免過號 !</h2>
        {(store.last || []).length > 0 && (
          <h3 style={{marginTop: 20}}>過去叫號紀錄</h3>
        )}
        {avgWaitingTime(store.last.map(({raw}) => raw)) !==
          Number.MAX_VALUE && (
          <h3 style={{marginTop: 15, color: 'red'}}>
            最近幾筆平均等待時間{' '}
            {avgWaitingTime(store.last.map(({raw}) => raw))} 分(僅供參考)
          </h3>
        )}
        {(store.last || []).map((record, idx) => (
          <Row key={idx} css="margin-top:10px;">
            <p style={{color: 'green', fontSize: 18, marginRight: 20}}>
              {(record.num + '').padStart(3, 0)}
            </p>
            <p>{record.time}</p>
          </Row>
        ))}
      </div>

      {store.enableLine && (
        <Button
          style={{marginTop: 20, marginBottom: 20, borderRadius: 10}}
          onClick={() => navigate('/lineNotify?sid=' + store.id)}>
          加「FreeStay」好友，開啟 Line 叫號通知
        </Button>
      )}
    </Col>
  );
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
  width: 100%;
  ${(props) => props.css || ''};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'space-between'};
  width: 100%;
  ${(props) => props.css || ''};
`;

const NumPad = styled.div`
  width: 20%;
  height: 20%;
  min-width: 150px;
  min-height: 150px;

  border-radius: 20px;
  border-width: 2px;
  border-color: black;

  background-color: ${(props) => props.bgColor};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 50px;
  font-weight: 500;
  color: ${(props) => props.textColor};

  position: relative;

  ${(props) => props.css || ''};
`;

export {StoreSummarySection, StoreStatusSection};
